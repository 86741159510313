/*.greenheader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    background-color: rgb(56, 177, 133);
    color: white;
  }
  */
  
  .header {
    background-color: rgb(70, 128, 70);
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }
  
  .logo-placeholder {
    /* Style your logo placeholder as needed */
    margin: 0;
  }
  
  .title {
    margin: 0;
  }
  
  .hamburger-menu {
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
  
  .bar {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 3px 0;
  }
  
  .dropdown-menu   
   {
    position: fixed;
    top: 150px;
    right: 0;
    background-color: rgb(5, 125, 5);
    width: 150px;
    padding: 1rem;
  }
  
  .dropdown-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .dropdown-menu li {
    margin-bottom: 0.5rem;
  }
  
  .dropdown-menu a {
    color: white;
    text-decoration: none;
  }